
import { Options, Vue } from 'vue-class-component';
import { mapState } from 'vuex';

import { accessibleAPIPlan, SUBSCRIPTION_PLAN } from '@/subscription';

import { ToggleModal } from '@/store/modules/modals';

@Options({
  computed: {
    ...mapState(['profile', 'hijackingToken']),
  },
})
export default class APIWrapper extends Vue {
  public checkAccessibleAPIPlan(plan: SUBSCRIPTION_PLAN): boolean {
    return accessibleAPIPlan.some((p) => p === plan);
  }

  public showModalChangeSubscription(): void {
    this.$store.commit('modals/toggle', { modal: 'changeSubscription', status: true } as ToggleModal);
  }
}
